import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
const ProductPage = ({ page }) => (
  <Layout pageInfo={page}>
    <SEO title={page.displayName} description={page.desc} />
    <div className='container py-5' style={{ marginTop: 75 }}>
      <div className=''>
        <div className='heading'>
          <h1>{page.displayName}</h1>
          <div className='line my-4'></div>

          <p className='lead'>{page.detailed_desc}</p>
        </div>
      </div>
    </div>
    <div className='container '>
      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          <p className='lead my-5'>
            <strong>Case Study:</strong> {page.case_study}
          </p>
        </div>
      </div>
      {!page.hide_before_after ? (
        <div className='row text-center'>
          <div className='col-md-6 pb-5'>
            <div className='before-processing'>
              <div className='text-center'>
                <h5 className='mb-3 mr-5'>Before Processing</h5>
                <div className='img-container'>
                  {/* <img className='mac-img' src={macImg} alt='mac' /> */}
                  <img className='block-img' src={page.before_img} alt='blk' />
                  {/* <img
                    className='section-img'
                    src={page.before_section}
                    alt='section'
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 pb-5'>
            <div className='after-processing'>
              {' '}
              <div className='text-center'>
                <h5 className='mb-3 mr-5'>After Processing</h5>
                <div className=' img-container'>
                  {/* <img className='mac-img' src={macImg} alt='mac' /> */}
                  <img className='block-img' src={page.after_img} alt='blk' />
                  {/* <img
                    className='section-img'
                    src={page.after_section}
                    alt='section'
                  />*/}
                </div>
              </div>
              {page.legends && page.legends.length ? (
                <div className='text-center'>
                  <div className='my-5 legends'>
                    {page.legends.map((legend, i) => (
                      <div className='legend pr-5 pb-2' key={i}>
                        {/* <img
                        src={legend.img}
                        alt={legend.title}
                        className='legend_img mr-4 '
                      /> */}
                        {legend.title === 'Tree' ? (
                          <div className='square'>
                            <div className='circle'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Planting Lines' ? (
                          <div className='square white'>
                            <div className='planting_lines'></div>
                          </div>
                        ) : null}
                        {legend.title === 'High risk trees' ? (
                          <div className='square white'>
                            <div className='circle high_risk'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Medium risk trees' ? (
                          <div className='square white'>
                            <div className='circle medium_risk'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Low risk trees' ? (
                          <div className='square white'>
                            <div className='circle low_risk'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Ripe' ? (
                          <div className='square white'>
                            <div className='circle ripe'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Over ripe' ? (
                          <div className='square white'>
                            <div className='circle over_ripe'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Unripe' ? (
                          <div className='square white'>
                            <div className='circle unripe'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Under ripe' ? (
                          <div className='square white'>
                            <div className='circle under_ripe'></div>
                          </div>
                        ) : null}
                        {legend.title === 'Smallholder palm area' ? (
                          <div className='square white small_holder'></div>
                        ) : null}
                        {legend.title === 'Large plantation palm area' ? (
                          <div className='square white large_holder'></div>
                        ) : null}
                        <div className='pl-3'>{legend.title}</div>
                      </div>
                    ))}{' '}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {page.processed_img ? (
        <>
          <div
            className={`row mx-auto  ${
              page.name !== 'smartgrader' ? 'pb-5' : ''
            }`}
          >
            <div className='col-lg-12'>
              <img
                src={page.processed_img}
                className='processed-img '
                alt='processed-img'
              ></img>
              {page.processed_img_text ? (
                <h5 className='py-4 text-center'> {page.processed_img_text}</h5>
              ) : null}
            </div>
          </div>
          {page.legends &&
          page.legends.length &&
          page.name === 'smartgrader' ? (
            <div className='my-5 legends row mx-auto'>
              {page.legends.map((legend, i) => (
                <div
                  className=' d-none d-md-flex col-sm-6 col-md-3 col-lg-3 pb-2'
                  key={i}
                >
                  {legend.title === 'Ripe' ? (
                    <div className='square white'>
                      <div className='circle ripe'></div>
                    </div>
                  ) : null}
                  {legend.title === 'Over ripe' ? (
                    <div className='square white'>
                      <div className='circle over_ripe'></div>
                    </div>
                  ) : null}
                  {legend.title === 'Unripe' ? (
                    <div className='square white'>
                      <div className='circle un_ripe'></div>
                    </div>
                  ) : null}
                  {legend.title === 'Under ripe' ? (
                    <div className='square white'>
                      <div className='circle under_ripe'></div>
                    </div>
                  ) : null}

                  <div className='pl-3'>{legend.title}</div>
                </div>
              ))}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
    {page.key_outputs && page.key_outputs.length ? (
      <div className='ceres_accuracy'>
        <div className='row container mx-auto text-center px-0'>
          {page.key_outputs.map((op, i) => (
            <div className={`py-5 px-0 ${op.class}`} key={i}>
              <div className='title'>{op.top}</div>
              <div className='benefit'>{op.benefit}</div>
              <div className='title'>{op.bottom}</div>
            </div>
          ))}
        </div>
      </div>
    ) : null}
  </Layout>
);

export default ProductPage;
